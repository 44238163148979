import i18n from '@/utils/lang'
import { deleteCookie } from '@common/cookie'
import { singleSignOnURL, singleSignOutURL } from '@/config/config'

function isEmptyObject(obj) {
  // 检查参数是否为对象
  if (typeof obj !== 'object' || obj === null) {
    return true
  }

  // 检查对象是否有可枚举的属性
  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      return false
    }
  }

  return true
}

function copy(data) {
  const url = data
  const oInput = document.createElement('input')
  oInput.value = url
  document.body.appendChild(oInput)
  oInput.select() // 选择对象;
  document.execCommand('Copy') // 执行浏览器复制命令
  layer.msg(i18n.t('已复制'), { timeout: 800 })
  oInput.remove()
}

//将逗号、空格隔开的订单字符串变成订单数组
function handleOrderIds(ids) {
  let arr = ids
    .replaceAll(',', ' ')
    .split(' ')
    .filter((item) => item)
  return arr
}

//校验订单号类型
function judgeOMSOrderSn(orderSns, list) {
  let type //platformOrderSn 原始订单号, orderSn OMS订单号, undefined 混合了，报错
  let platformNum = 0,
    orderNum = 0
  let arr = handleOrderIds(orderSns)
  arr.forEach((item) => {
    if (item.startsWith('XS')) {
      orderNum++
    } else {
      platformNum++
    }
  })
  if (platformNum * orderNum === 0) {
    if (platformNum === 0 && orderNum > 0) {
      type = list[1]
    }
    if (platformNum > 0 && orderNum === 0) {
      type = list[0]
    }
  } else {
    type = undefined
  }
  return type
}

function toThousandAmount(num) {
  num = num.toString().split('.') // 分隔小数点
  var arr = num[0].split('').reverse() // 转换成字符数组并且倒序排列
  var res = []
  for (var i = 0, len = arr.length; i < len; i++) {
    if (i % 3 === 0 && i !== 0) {
      res.push(',') // 添加分隔符
    }
    res.push(arr[i])
  }
  res.reverse() // 再次倒序成为正确的顺序
  if (num[1]) {
    // 如果有小数的话添加小数部分
    res = res.join('').concat('.' + num[1])
  } else {
    res = res.join('')
  }
  return res
}

function timeLeft(targetTime) {
  const now = new Date() // 当前时间
  const target = new Date(targetTime) // 目标时间

  // 计算时间差（毫秒）
  let timeDiff = target - now

  let str,
    isTimeout = timeDiff < 0

  timeDiff = Math.abs(timeDiff)
  // 计算小时数和分钟数
  const hoursLeft = timeDiff / (1000 * 60 * 60)
  const minutesLeft = timeDiff / (1000 * 60)

  if (hoursLeft >= 1) {
    str = `${hoursLeft.toFixed(1)} ${i18n.t('小时')}`
  } else {
    str = `${minutesLeft.toFixed(1)} ${i18n.t('分钟')}`
  }

  if (isTimeout) {
    str = i18n.t('逾期T', { T: str })
  }
  return str
}

function logout() {
  deleteCookie()
  localStorage.clear()
  window.location.href = singleSignOutURL + '?redirect_uri=' + encodeURIComponent(singleSignOnURL)
}

function getSystemName(appCode) {
  let systemName
  if (['SALE_MANAGE', 'PURCHASE_MANAGE', 'GOODS'].includes(appCode)) {
    systemName = 'OMS'
  } else if (appCode === 'ULTRA_BI') {
    systemName = 'BI'
  } else if (appCode === 'FMS') {
    systemName = 'FMS'
  } else {
    systemName = 'WMS'
  }
  return systemName
}

function getSystemRoutePath(appCode) {
  let obj = {
    SALE_MANAGE: 'orderManage',
    PURCHASE_MANAGE: 'purchase/purchaseList',
    GOODS: 'goodsManage',
    PRODUCTION_MANAGE: 'inWarehouse/productionPlan',
    WAREHOUSE_MANAGE: 'outOfWarehouse/shipmentOrderManage',
    ULTRA_BI: 'qbiDashboard',
    FMS: 'payableManage/payableList',
  }
  return obj[appCode]
}

export {
  isEmptyObject,
  copy,
  handleOrderIds,
  toThousandAmount,
  timeLeft,
  judgeOMSOrderSn,
  logout,
  getSystemName,
  getSystemRoutePath,
}
