import Vue from 'vue'
import VueRouter from 'vue-router'
import { getCookie } from '@/common/cookie'
import { getSystemName, getSystemRoutePath, logout } from '@/common'
//系统
const MicroWeb = () => import('./view/micro/index.vue')

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    {
      path: '/',
      name: 'index',
      redirect: '/login',
      component: () => import('./view/index'),
      children: [
        //微应用
        {
          path: 'OMS',
          name: 'OMS',
          component: MicroWeb,
        },
        {
          path: 'OMS/*',
          name: 'OMS/',
          component: MicroWeb,
        },
        {
          path: 'WMS',
          name: 'WMS',
          component: MicroWeb,
        },
        {
          path: 'WMS/*',
          name: 'WMS/',
          component: MicroWeb,
        },
        {
          path: 'BI',
          name: 'BI',
          component: MicroWeb,
        },
        {
          path: 'BI/*',
          name: 'BI/',
          component: MicroWeb,
        },
        {
          path: 'FMS',
          name: 'FMS',
          component: MicroWeb,
        },
        {
          path: 'FMS/*',
          name: 'FMS/',
          component: MicroWeb,
        },
        {
          path: '/404',
          name: '404',
          component: () => import('./view/common/404'),
        },
      ],
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('./view/login'),
    },
    {
      path: '/csvPreview',
      name: 'csvPreview',
      component: () => import('./view/csvPreview'),
    },
    {
      path: '/403',
      name: '403',
      component: () => import('./view/common/403'),
    },
    // 此处需特别注意至于最底部
    {
      path: '*',
      redirect: '/404',
    },
  ],
  scrollBehavior: () => ({
    y: 0,
  }),
  mode: 'history',
})

router.beforeEach((to, from, next) => {
  if (to.name === 'login') {
    if (!getCookie('d_session_id')) {
      next()
    } else {
      let currentApp = localStorage.getItem('currentApp')
      let systemName, routePath
      if (currentApp) {
        try {
          currentApp = JSON.parse(currentApp)
          systemName = getSystemName(currentApp.appCode)
          routePath = getSystemRoutePath(currentApp.appCode)
        } catch (err) {
          console.log('err: ', err)
          logout()
          return
        }
      }
      next(`/${systemName}/${routePath}`)
    }
  } else if (!getCookie('d_session_id')) {
    localStorage.setItem('pageCacheFullPath', to.fullPath)
    next({ name: 'login' })
    return
  }
  next()
})

export default router
